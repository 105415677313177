import axios from 'axios';

const baseURL = `${process.env.VUE_APP_NEW_API_URL}`

export default {
    registerScbEasyApp(id, data) {
        try {
            return axios.post(`${baseURL}/auto-banking/${id}/scb`, data)
        } catch (e) {
            throw e
        }
    },
    verifyScbOTP(id, data) {
        try {
            return axios.post(`${baseURL}/auto-banking/${id}/scb/otp`, data)
        } catch (e) {
            throw e
        }
    },
    registerScbEasyNet(id, data) {
        try {
            return axios.post(`${baseURL}/auto-banking/${id}/scb-easy-net`, data)
        } catch (e) {
            throw e
        }
    },
    registerKBiz(id, data) {
        try {
            return axios.post(`${baseURL}/auto-banking/${id}/kbiz`, data)
        } catch (e) {
            throw e
        }
    },
    registerKPlus(id, data) {
        try {
            return axios.post(`${baseURL}/auto-banking/${id}/kplus`, data)
        } catch (e) {
            throw e
        }
    },
    registerBay(id, data) {
        try {
            return axios.post(`${baseURL}/auto-banking/${id}/bay`, data)
        } catch (e) {
            throw e
        }
    },
    requestKKPOtp(id, data) {
        try {
            return axios.post(`${baseURL}/auto-banking/${id}/kkp`, data)
        } catch (e) {
            throw e
        }
    },
    submitKKPOtp(id, data) {
        try {
            return axios.post(`${baseURL}/auto-banking/${id}/kkp/otp`, data)
        } catch (e) {
            throw e
        }
    },
    uploadKKPImage(id, data) {
        try {
            return axios.post(`${baseURL}/auto-banking/${id}/kkp/image`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        } catch (e) {
            throw e
        }
    },
    submitKTB(id, data) {
        try {
            return axios.post(`${baseURL}/auto-banking/${id}/ktb`, data)
        } catch (e) {
            throw e
        }
    },
    requestGSBOtp(id, data) {
        try {
            return axios.post(`${baseURL}/auto-banking/${id}/gsb`, data)
        } catch (e) {
            throw e
        }
    },
    submitGSBOtp(id, data) {
        try {
            return axios.post(`${baseURL}/auto-banking/${id}/gsb/otp`, data)
        } catch (e) {
            throw e
        }
    },
    uploadGSBImage(id, data) {
        try {
            return axios.post(`${baseURL}/auto-banking/${id}/gsb/image`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        } catch (e) {
            throw e
        }
    },
    registerTrueWallet(id, data) {
        try {
            return axios.post(`${baseURL}/auto-banking/${id}/true-wallet`, data)
        } catch (e) {
            throw e
        }
    },
    registerTrueWebhook(id, data) {
        try {
            return axios.post(`${baseURL}/auto-banking/${id}/true-money-webhook`, data)
        } catch (e) {
            throw e
        }
    },
    registerSMS(id, data) {
        try {
            return axios.post(`${baseURL}/auto-banking/${id}/sms`, data)
        } catch (e) {
            throw e
        }
    },
    registerNotify(id, data) {
        try {
            return axios.post(`${baseURL}/auto-banking/${id}/notify`, data)
        } catch (e) {
            throw e
        }
    },
    toggleBankingStatus(id, banking) {
        try {
            return axios.post(`${baseURL}/auto-banking/${id}/${banking}/toggle`)
        } catch (e) {
            throw e
        }
    },
}